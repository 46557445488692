<template>
	<v-dialog max-width="800" v-model="uploader" persistent>
		<v-card class="sticker-uploader">
			<v-card-text class="pa-0">
				<div class="upload-header">
					<h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">TTS Voice Lab Creator</h3>

					<h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">TTS Voice Lab Creator</h3>

					<v-icon class="close-icon" @click="actions.closeWizard">{{ icons.closeIcon }}</v-icon>
				</div>
				<v-divider class="purple"></v-divider>
				<v-card class="sticker-upload-stepper">
					<v-stepper class="px-0" flat v-model="step">
						<v-stepper-header class="px-0">
							<v-stepper-step :step="1">
								<Step
									:step="step"
									:current="1"
									:title="voice.type === VoiceLabUploadType.RECORD ? 'Record' : 'Upload'"
									subtitle="Voice Sample" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(1)"></v-divider>

							<v-stepper-step :step="2">
								<Step
									:step="step"
									:current="2"
									title="Review Audio"
									:subtitle="
										voice.type === VoiceLabUploadType.RECORD ? 'Recording' : 'Voice Sample'
									" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(2)"></v-divider>

							<v-stepper-step :step="3">
								<Step :step="step" :current="3" title="Review A.I" subtitle="Voice Model" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(3)"></v-divider>

							<v-stepper-step :step="4">
								<Step :step="step" :current="4" title="Voice" subtitle="Sharing" />
							</v-stepper-step>
						</v-stepper-header>
						<v-divider class="purple"></v-divider>
						<v-stepper-items>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="1">
								<RecordVoice
									v-if="step === 1 && voice.type === VoiceLabUploadType.RECORD"
									:voice="voice"
									@onManualUpload="actions.onManualUpload"
									@onAudioRecorded="actions.onAudioRecorded"
									@onMove="actions.changeStep" />

								<UploadFile
									v-if="step === 1 && voice.type !== VoiceLabUploadType.RECORD"
									:voice="voice"
									@onAudioPicked="actions.onAudioPicked"
									@onAgree="actions.onAgree"
									@onRecordVoice="actions.onRecordVoice"
									@onMove="actions.changeStep" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="2">
								<ReviewRecording v-if="step === 2" :voice="voice" @onMove="actions.changeStep" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="3">
								<PreviewVoice
									v-if="step === 3"
									:voice="voice"
									@onMove="actions.changeStep"
									@onAccentChange="actions.onAccentChange" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="4">
								<ShareVoice
									v-if="step === 4"
									:voice="voice"
									@onAddUsers="actions.onAddUsers"
									v-on:onMove="actions.changeStep"
									@onSave="actions.saveChanges" />
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { reactive, ref, onMounted } from '@vue/composition-api';
import axios from '@axios';
import { mdiClose } from '@mdi/js';
import Step from './Step';
import UploadFile from './UploadFile';
import RecordVoice from './RecordVoice';
import ReviewRecording from './ReviewRecording';
import PreviewVoice from './PreviewVoice';
import ShareVoice from './ShareVoice';
import { VoiceDefaults, VoiceLabUploadType } from '@/utils/constants';
import { saveEvent } from '@/utils/gtag';

export default {
	components: {
		Step,
		RecordVoice,
		UploadFile,
		ReviewRecording,
		PreviewVoice,
		ShareVoice,
	},
	props: {
		uploader: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onUploaded', 'onClose'],

	setup(props, ctx) {
		const step = ref(1);
		const isPlaying = ref(false);
		const voice = reactive({
			type: VoiceLabUploadType.RECORD,
			audioBase64: null,
			recordedBlob: null,
			agreeTerms: false,
			enableAccentEnhancement: false,
			stability: VoiceDefaults.STABILITY,
			style: VoiceDefaults.STYLE,
			shares: [],
		});

		onMounted(() => {
			saveEvent({
				event: 'voice_upload_begin',
				params: {
					event_category: 'engagement',
					event_label: 'User Starts the Custom Voice Model Creation',
					item_category: 'ELEVEN_LABS',
				},
			});
		});

		// Convert recorded audio to Base64 for upload.
		const convertBlobForUpload = blob => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onload = function () {
				voice.audioBase64 = reader.result;
			};
		};

		const actions = {
			addLineClass: function (tab) {
				if (step.value === tab) {
					return 'step-dash purpleDivider';
				}
				if (step.value > tab) {
					return 'step-dash pink';
				}
				if (step.value < tab) {
					return 'step-dash purpleDivider';
				}
			},

			changeStep: function (value) {
				step.value = value;
			},

			// Manual file picked, store blob for replay and convert to Base64 for upload.
			onAudioPicked(file) {
				voice.recordedBlob = URL.createObjectURL(file);
				convertBlobForUpload(file);
			},

			// Audio recording complete, store blob for replay and convert to Base64 for upload.
			onAudioRecorded(blob) {
				voice.recordedBlob = URL.createObjectURL(blob);
				convertBlobForUpload(blob);
			},

			onAgree(value) {
				voice.agreeTerms = value;
			},

			onRecordVoice() {
				voice.type = VoiceLabUploadType.RECORD;
			},

			onManualUpload() {
				voice.type = VoiceLabUploadType.MANUAL;
			},

			onAccentChange(value) {
				voice.enableAccentEnhancement = value;
			},

			onAddUsers(users) {
				voice.shares = [...users];
			},

			saveChanges: function () {
				axios
					.post('/tts/vlab/voice', voice)
					.then(() => {
						saveEvent({
							event: 'voice_upload_complete',
							params: {
								event_category: 'engagement',
								event_label: 'User Completes the Cutom Voice Model Creation',
								item_category: 'ELEVEN_LABS',
								method: voice.type === VoiceLabUploadType.RECORD ? 'on-site' : 'manual',
							},
						});

						ctx.emit('onUploaded', voice);
						ctx.emit('onClose');
					})
					.catch(error => console.log(error));
			},

			closeWizard: function () {
				ctx.emit('onClose');
			},
		};

		return {
			actions,
			voice,
			step,
			isPlaying,
			VoiceLabUploadType,
			icons: {
				closeIcon: mdiClose,
			},
		};
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.sticker-upload-stepper {
	max-height: 100px;
	overflow-y: auto;

	// @media screen and (min-height: 300px) {
	// 	max-height: 200px;
	// }

	// @media screen and (min-height: 466px) {
	// 	max-height: 350px;
	// }

	// @media screen and (min-height: 600px) {
	// 	max-height: 480px;
	// }

	// @media screen and (min-height: 730px) {
	// 	max-height: 630px;
	// }

	// @media screen and (min-height: 800px) {
	// 	max-height: 90%;
	// }
}
</style>
