<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text class="pb-4">
			<h4 class="fs--15 fw--500 text-center">Create New A.I. TTS Voice</h4>

			<div class="text-center upload-voice d-flex flex-column justify-center align-center">
				<div>
					<v-icon color="#8F6AF0" size="36">{{ icons.chevRight }}</v-icon>
					<v-btn fab color="pink" width="100" class="mx-2" height="100" @click="actions.addVoice">
						<v-icon size="38">{{ icons.plus }}</v-icon>
					</v-btn>
					<v-icon color="#8F6AF0" size="36">{{ icons.chevLeft }}</v-icon>
				</div>
			</div>

			<v-divider class="purpleDivider"></v-divider>

			<div class="text-center pa-4 px-2">
				<span class="fs--15 fw--500">TTS Voice Sharing</span>
				<p class="fs--12 fw--100 mt-2">
					Create your TTS Voice to enable sharing. Once created, you can share your TTS Voice with as many
					broadcasters as you like.
				</p>

				<div class="d-flex justify-center mb-2">
					<v-avatar size="60" class="avatar-stroke">
						<v-img :src="jwt.picture"></v-img>
					</v-avatar>
				</div>

				<img src="@/assets/images/Voice Sharing.png" alt="" />
				<!-- <v-btn depressed color="lightenPurple">
					<v-icon class="mr-2">{{ icons.addUser }}</v-icon>
					<span class="fs--13 fw--400">Share Voice</span>
				</v-btn> -->
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { parseAccessToken } from '@/utils/auth';
import { mdiPlus, mdiAccountMultiplePlus, mdiDelete, mdiChevronDoubleLeft, mdiChevronDoubleRight } from '@mdi/js';

export default {
	props: {
		item: {
			type: Object,
		},
	},
	setup(props, ctx) {
		const jwt = parseAccessToken();

		const actions = {
			addVoice() {
				ctx.emit('onUploadVoice');
			},
		};
		return {
			jwt,
			actions,
			icons: {
				plus: mdiPlus,
				addUser: mdiAccountMultiplePlus,
				delete: mdiDelete,
				chevLeft: mdiChevronDoubleLeft,
				chevRight: mdiChevronDoubleRight,
			},
		};
	},
};
</script>

<style scoped>
.upload-voice {
	height: 175px;
	width: 100%;
}

.avatar-stroke {
	border: 2px solid #53ffff;
}
</style>
