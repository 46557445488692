<template>
	<div class="voice-shares">
		<v-text-field
			v-model="search"
			solo
			background-color="purple"
			:append-icon="icons.search"
			placeholder="Search Creators"
			dense
			hide-details></v-text-field>

		<div class="text-center my-3">
			<span class="fs--14 fw--100 white--text">Active Shares ({{ items.length }})</span>
		</div>

		<v-data-table
			class="activity-feed-table"
			:headers="headers"
			:search="search"
			hide-default-header
			:items="items"
			:items-per-page="3"
			:hide-default-footer="items.length < 1">
			<template v-slot:no-data>
				<span>Not Currently Sharing</span>
			</template>
			<template v-slot:[`item.profilePic`]="{ item }">
				<v-avatar size="30">
					<img
						:src="actions.profilePic(item.profilePic)"
						@error="$event.target.src = `${actions.defaultProfilePic()}`" />
				</v-avatar>
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<v-btn icon color="red" @click="actions.deleteUser(item)">
					<v-icon>{{ icons.delete }}</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiMagnify, mdiDelete } from '@mdi/js';
export default {
	props: {
		items: {
			type: Array,
		},
	},
	setup(props) {
		const search = ref('');
		const headers = [
			{ text: 'image', value: 'profilePic', class: 'user-avatar' },
			{ text: 'name', value: 'name', sortable: true, filterable: true },
			{ text: '', value: 'action', sortable: false, filterable: false },
		];

		const actions = {
			deleteUser(user) {
				const index = props.items.findIndex(item => item.id === user.id);
				props.items.splice(index, 1);
			},

			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},

			profilePic: function (profilePic) {
				if (profilePic) {
					return profilePic;
				} else {
					return actions.defaultProfilePic();
				}
			},
		};

		return {
			search,
			headers,

			actions,

			icons: {
				search: mdiMagnify,
				delete: mdiDelete,
			},
		};
	},
};
</script>

<style scoped>
.user-avatar {
	width: 30px;
	max-width: 30px !important;
}
</style>
