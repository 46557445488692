<template>
	<v-card min-height="457" height="100%" class="card-border shared-slot">
		<v-card-text v-if="item" class="pb-4 voice-slot">
			<v-tooltip top color="tooltipBg">
				<template v-slot:activator="{ on, attrs }">
					<div v-if="voiceStickers && voiceStickers.length < 1" class="exclamation" v-bind="attrs" v-on="on">
						<img
							width="20"
							class="pointer-events-none"
							src="@/assets/icons/red-exclamation.png"
							@click.stop />
					</div>
				</template>
				<strong class="offWhite--text">
					This voice is not yet assigned to any TTS stickers.<br />
					Tap the Assign Voice button to assign it to a TTS Sticker.
				</strong>
			</v-tooltip>

			<div class="d-flex align-center justify-center mb-5 mt-2">
				<img src="@/assets/icons/AIShared.png" class="mr-1" />
				<h4 class="fs--18 fw--300 text-center">{{ item.name }} - Shared</h4>
			</div>

			<div v-if="item.ownership == VoiceLabOwnership.SHARED_TO" class="d-flex justify-center mb-5">
				<v-avatar size="80" class="avatar-stroke">
					<v-img v-if="item.sharedFrom" :src="item.sharedFrom.profilePic"></v-img>
				</v-avatar>
			</div>

			<p class="fs--15 fw--500 text-center mb-1">TTS Voice Preview</p>

			<div class="voice pt-3 mb-5">
				<MusicPlayer :audio="audioUrl" :id="'voice-preview-' + item.id" />
			</div>

			<v-divider class="purpleDivider"></v-divider>

			<div class="text-center pa-4">
				<span class="fs--15 fw--500">TTS Voice Sticker Assignment</span>
			</div>

			<template v-if="!voiceStickers.length">
				<div class="text-center upload-voice d-flex flex-column justify-center align-center">
					<v-btn @click="actions.gotoStickers" depressed color="pink" class="mb-2">
						<v-icon size="16" class="mr-1">{{ icons.open }}</v-icon> <span>Assign Voice</span>
					</v-btn>
					<p class="fs--12 fw--100 mt-2 mb-0 offWhite--text">
						To use the voice, you must first assign it to a
						<span @click="actions.gotoStickers" class="pink--text cursor-pointer text-underline fw--600"
							>TTS Sticker</span
						>. It will now appear in your list of A.I. configurable Voices as
						<img width="10" class="mr-1" src="@/assets/icons/AIShared.png" alt="Shared" />
						<span class="white--text fw--500">[A.I. Shared] {{ item.name }}</span>
					</p>
				</div>
			</template>

			<LinkedStickers v-else :stickers="voiceStickers" @addSticker="actions.gotoStickers" />

			<div class="text-center pb-4">
				<span v-if="voiceStickers && voiceStickers.length > 0" class="fs--13 fw--100 offWhite--text"
					>This voice has been assigned to {{ voiceStickers.length }} TTS
					{{ voiceStickers.length > 1 ? 'Stickers' : 'Sticker' }}</span
				>
			</div>

			<div class="text-center">
				<v-btn @click="actions.deleteVoice(item.id)" color="secondary" depressed>
					<v-icon class="mr-2">{{ icons.delete }}</v-icon>
					<span class="fs--13 fw--400">Delete Voice</span>
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { onBeforeUnmount, computed, ref } from '@vue/composition-api';
import router from '@/router';
import axios from '@axios';
import LinkedStickers from './LinkedStickers.vue';
import MusicPlayer from './MusicPlayer.vue';
import { FormChangeWatcher } from '../../../utils/form';
import { StickerType, VoiceLabOwnership } from '../../../utils/constants';
import { getVoicePreviewUrl } from '../../../utils/tts-utils';
import { mdiAccountMultiplePlus, mdiDelete, mdiPlus, mdiOpenInNew } from '@mdi/js';
export default {
	name: 'SharedVoiceSlot',
	props: {
		item: {
			type: Object,
		},
	},
	components: {
		LinkedStickers,
		MusicPlayer,
	},
	emits: ['onDelete'],
	setup(props, ctx) {
		const shareModal = ref(false);
		const newUser = ref('');
		const isLoading = ref(false);

		// Stickers assigned to this voice.
		const voiceStickers = computed(() => {
			if (props.item && props.item.assignedStickers) {
				return props.item.assignedStickers;
			}
			return [];
		});

		const audioUrl = computed(() => {
			return getVoicePreviewUrl(props.item.id);
		});

		const actions = {
			deleteVoice(id) {
				axios.delete(`/tts/vlab/shared-voice/${id}`).then(() => {
					ctx.emit('onDelete', id);
				});
			},
			gotoStickers() {
				router.push('/stickers?tab=' + StickerType.TTS);
			},
		};

		// Watch for changes in the form.
		const formChangeWatcher = new FormChangeWatcher();
		formChangeWatcher.startWatching(props.item, actions.saveChanges);

		onBeforeUnmount(() => {
			formChangeWatcher.stopWatching();
		});

		return {
			shareModal,
			newUser,
			isLoading,
			voiceStickers,
			audioUrl,
			actions,
			VoiceLabOwnership,
			icons: {
				plus: mdiPlus,
				addUser: mdiAccountMultiplePlus,
				delete: mdiDelete,
				open: mdiOpenInNew,
			},
		};
	},
};
</script>

<style scoped>
.voice-slot {
	position: relative;
}
.exclamation {
	position: absolute;
	top: 8px;
	right: 8px;
}
.upload-voice {
	height: 155px;
	width: 100%;
}

.avatar-stroke {
	border: 2px solid #53ffff;
}
</style>
