<template>
	<GenericPopup :modal="modal" :closeButton="false" :maxWidth="600" @close="$emit('close')">
		<template #primaryHeader>TTS Voice Model Created!</template>
		<template>
			<div class="d-flex align-center justify-center">
				<!-- Set Icon Dynamically based on the Voice -->
				<img src="@/assets/icons/AIPersonal.png" class="mr-1" />
				<h4 class="fs--18 fw--300 text-center mb-3 mt-2">[A.I. Personal] {{ jwt.displayName }}</h4>
			</div>

			<div class="voice-section mt-3 mb-4">
				<MusicPlayer v-if="audioUrl" :audio="audioUrl" :id="'voice-preview-' + voice.id" />
			</div>

			<p class="offWhite--text text-center">
				Your new TTS voice model has been successfully created! You can now
				<span class="blueWaves--text">assign this voice to an existing TTS sticker</span>, or
				<span class="blueWaves--text">create a new TTS Sticker</span> if you have any available TTS Sticker
				Slots as shown below.
			</p>

			<div class="offWhite--text text-center">
				<span>This voice will now appear as</span>
				"<img src="@/assets/icons/AIPersonal.png" class="mr-1" />
				<span class="white--text">[A.I. Personal] {{ jwt.displayName }}</span
				>" in your list of custom A.I. Voices when creating or editing TTS Stickers.
			</div>

			<v-row justify="center" class="mt-5">
				<v-col v-for="item in voiceStickers" :key="item.id" cols="6" sm="4" class="text-center">
					<!-- Sticker configured in slot. -->
					<div v-if="item.isSlotConfigured">
						<h4 class="fs--16 fw--400 mb-2">{{ item.name }}</h4>
						<AssetsTTS :item="item" size="35" />
						<!-- Set Assigned if sticker already assigned -->
						<v-btn
							v-if="!item.isVoiceAssigned"
							@click="actions.assignVoice(item)"
							color="pink"
							x-small
							depressed
							class="mt-2"
							>Assign Voice</v-btn
						>
						<v-btn v-else color="blueWaves" x-small depressed class="mt-2 black--text">Assigned</v-btn>
					</div>
					<!-- Empty slot, no sticker configured. -->
					<div v-else>
						<h4 class="fs--16 fw--400 mb-2">Create New TTS Sticker (Slot {{ item.slotIndex + 1 }})</h4>
						<v-btn fab color="pink" width="80" height="80" class="mt-3" @click="actions.gotoStickers">
							<v-icon size="34">{{ icons.plus }}</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<div class="text-center mt-5">
				<span class="text-underline cursor-pointer" @click="$emit('close')">Skip Now</span>
			</div>
		</template>
	</GenericPopup>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api';
import router from '@/router';
import axios from '@axios';
import GenericPopup from '@/components/GenericPopup';
import AssetsTTS from '../stickers/AssetsTTS.vue';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import { parseAccessToken } from '@/utils/auth';
import { getVoicePreviewUrl } from '@/utils/tts-utils';
import { StickerType } from '@/utils/constants';
import { mdiPlus } from '@mdi/js';

export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
		voice: {
			type: Object,
			default: null,
		},
	},
	components: {
		GenericPopup,
		MusicPlayer,
		AssetsTTS,
	},
	setup(props, ctx) {
		const voiceStickers = reactive([]);
		const audioUrl = ref(null);
		const jwt = parseAccessToken();

		// Load existing TTS stickers.
		const fetchData = () => {
			axios.get('/custom-stickers/TTS').then(res => {
				// Set additional values on items.
				const items = res.data.items.filter(s => !s.tts.isBonusTtsShareSlot);
				for (let i = 0; i < items.length; i++) {
					let c = items[i];
					c.isVoiceAssigned = false;
					c.slotIndex = i;
				}
				voiceStickers.length = 0;
				voiceStickers.push(...items);
			});
		};

		// When the modal is being shown, get data for the popup.
		watch(
			() => props.modal,
			() => {
				if (props.modal) {
					audioUrl.value = getVoicePreviewUrl(props.voice.id);
					fetchData();
				}
			},
		);

		const actions = {
			assignVoice(item) {
				axios.post(`/tts/vlab/voice-assignment/${props.voice.id}/${item.id}`).then(() => {
					item.isVoiceAssigned = true;
				});
			},
			gotoStickers() {
				router.push('/stickers?tab=' + StickerType.TTS);
			},
		};

		return {
			voiceStickers,
			jwt,
			audioUrl,
			actions,
			icons: {
				plus: mdiPlus,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.voice-section {
	width: 50%;
	margin: 0 auto;
}
</style>
