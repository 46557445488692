<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text class="pb-4 empty-slot text-center d-flex flex-column justify-center align-center">
			<div class="text-center pa-4">
				<img src="@/assets/images/mascot/ShareMascot.png" alt="Share Mascot" class="mb-5" />
				<div>
					<span class="fs--15 fw--500">Shared Voices will Appear Here!</span>
					<p class="fs--12 fw--100 mt-2 mb-0">
						Ask your friends to share their custom TTS Voice Model and they will appear here for you to use on your TTS stickers!
					</p>
					<p class="fs--12 fw--100 mt-1 mb-0">
						You will also get one additional Bonus TTS Sticker Slot for every voice model that is shared with you.
					</p>
					<p class="fs--12 fw--100 mt-1">
						There is no limit to how many voices can be shared with you.
					</p>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mdiLock, mdiAccountMultiplePlus, mdiDelete } from '@mdi/js';
export default {
	props: {},
	setup() {
		return {
			icons: {
				lock: mdiLock,
				addUser: mdiAccountMultiplePlus,
				delete: mdiDelete,
			},
		};
	},
};
</script>

<style scoped>
.empty-slot {
	height: 100%;
	width: 100%;
}
</style>
