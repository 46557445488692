<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Record Your Script:</h4>

				<div class="upload-area px-3 px-sm-5 text-center mt-4">
					<div class="text-center mt-1">
						<h4 class="text-center textColor--text fs--18 fw--500">
							Hit the record button and read the following lines.
						</h4>

						<p class="fs--13 fw--500 mb-5 mt-1 shinyPurple--text">
							Tip: For best results, please read each of the following script lines clearly and loudly as
							though you were streaming to a live audience. The system will attempt to match your
							intonation and excitement level. If you make a mistake, don't worry, just keep reading!
						</p>

						<v-carousel v-model="textLine" hide-delimiters :show-arrows="false" height="auto" class="mb-4">
							<v-carousel-item v-for="(line, index) in textArray" :key="index">
								<v-sheet color="transparent">
									<v-row class="fill-height px-3" align="center" justify="center">
										<v-col cols="12">
											<div class="text-center waveColor--text fs--19 fw--400">{{ line }}</div>
										</v-col>
									</v-row>
								</v-sheet>
							</v-carousel-item>
						</v-carousel>

						<div class="text-center position-relative mb-5">
							<div v-if="isRecording" class="playAnimation"></div>
							<v-btn
								v-if="!isRecording"
								fab
								small
								color="white"
								depressed
								@click="actions.toggleRecording()">
								<v-icon color="pink">{{ icons.mic }}</v-icon>
							</v-btn>
							<v-btn
								v-if="isRecording"
								fab
								small
								color="pink"
								depressed
								@click="actions.pauseRecording()">
								<v-icon color="white">{{ icons.pause }}</v-icon>
							</v-btn>
						</div>

						<div v-if="isRecording" class="d-flex flex-column justify-center align-center mb-3">
							<span class="fs--12 offWhite--text">For best results, speak loudly and clearly.</span>
							<!-- <div class="meter">
                                <div class="volume" :class="volumeLevel < -50 ? 'low-volume' : volumeLevel < -30 ? 'medium-volume' : 'optimum-volume'">
                                </div>
                            </div> -->
							<meter
								style="width: 150px; height: 25px"
								:value="volumeLevel"
								min="-75"
								max="0"
								low="-40"
								high="-10"
								optimum="-20"></meter>
						</div>

						<template v-if="textLine < textArray.length - 1 && showNext">
							<v-btn color="pink" class="px-10" depressed @click="actions.nextLine()">Next</v-btn>
						</template>
						<template v-if="textLine === textArray.length - 1">
							<v-btn color="pink" class="px-10" depressed @click="actions.stopRecording()">Submit</v-btn>
						</template>

						<div class="text-center mt-2">
							<span class="textColor--text fs--13 fw--400"
								>(STEP {{ textLine + 1 }} of {{ textArray.length }})</span
							>
						</div>

						<div class="text-right mt-2 px-1">
							<v-btn
								text
								color="pink"
								depressed
								class="text-capitalize"
								@click="actions.manualUpload()"
								:disabled="isVoiceUploading">
								Prefer Manual Upload?
							</v-btn>
						</div>
					</div>
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0 py-9">
			<!-- <v-btn class="my-3 my-sm-6 px-12" depressed @click="actions.moveNext" :disabled="!nextStep || !voice.recordedBlob || isVoiceUploading" color="pink">Next</v-btn> -->
		</div>
	</v-card>
</template>

<script>
import { ref, reactive, computed, watch, onBeforeUnmount } from '@vue/composition-api';
import { mdiPause, mdiMicrophone } from '@mdi/js';
import { textArray } from '@/utils/constants';

export default {
	props: {
		voice: {
			type: Object,
			required: true,
		},
	},
	emits: ['onMove', 'onManualUpload', 'onAudioRecorded'],
	setup(props, ctx) {
		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const textLine = ref(0);
		const recordedAudio = ref(null);
		const isVoiceUploading = ref(false);
		const showNext = ref(false);
		const showSubmit = ref(false);
		const nextStep = ref(false);

		const audioChunks = reactive([]);
		let audioContext = null;
		let mediaRecorder = null;
		const isRecording = ref(false);
		const isPaused = ref(false);

		const analyser = ref(null);
		const volumeLevel = ref(null);
		const streamBackup = ref(null);

		const dbValue = computed(() => {
			return 20 * Math.log10(volumeLevel.value);
		});

		// watch(textLine, (val, old) => {
		//     if (val === 0) {
		//         showNext.value = false
		//         showSubmit.value = false
		//     }
		//     if (val > old) {
		//         showNext.value = false
		//     }
		// })

		onBeforeUnmount(() => {
			if (window.localStream) {
				window.localStream.getTracks().forEach(track => track.stop());
			}
		});

		const actions = {
			nextLine: function () {
				if (textLine.value + 1 < textArray.length) {
					textLine.value++;
				}
			},
			manualUpload: function () {
				ctx.emit('onManualUpload');
			},

			toggleRecording() {
				if (!isRecording.value && isPaused.value) {
					actions.resumeRecording();
				} else {
					actions.startRecording();
				}

				showNext.value = true;
			},

			async resumeRecording() {
				if (mediaRecorder && isPaused.value) {
					mediaRecorder.resume();
					isRecording.value = true;
					isPaused.value = false;

					await new Promise(resolve => setTimeout(resolve, 100));
					const microphoneSource = audioContext.createMediaStreamSource(window.localStream);
					analyser.value.connect(audioContext.destination);

					// if (textLine.value === textArray.length - 1) {
					//     showSubmit.value = true
					// }
				}
			},

			pauseRecording() {
				if (!isPaused.value && isRecording.value) {
					mediaRecorder.pause();
					analyser.value.disconnect();

					isPaused.value = true;
					isRecording.value = false;
				}
			},

			startRecording() {
				audioChunks.length = 0;
				navigator.mediaDevices
					.getUserMedia({ audio: { channelCount: 2 } })
					.then(stream => {
						isRecording.value = true;
						isPaused.value = false;

						window.localStream = stream;

						mediaRecorder = new MediaRecorder(stream);

						mediaRecorder.ondataavailable = event => {
							if (event.data.size > 0) {
								audioChunks.push(event.data);
							}
						};

						mediaRecorder.onstop = () => {
							isRecording.value = false;

							analyser.value.disconnect();

							window.localStream.getTracks().forEach(track => track.stop());

							audioContext.close().then(() => {
								audioContext = null;
								analyser.value = null;
								mediaRecorder = null;
							});

							volumeLevel.value = 0;
							actions.createAudioBlob();
						};

						actions.initAudioContext(stream);

						mediaRecorder.start();
					})
					.catch(error => {
						console.error('Error accessing microphone:', error);
					});
			},

			stopRecording() {
				if (mediaRecorder) {
					mediaRecorder.stop();
					nextStep.value = true;
				}
			},
			createAudioBlob() {
				const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
				recordedAudio.value = URL.createObjectURL(audioBlob);

				// Submit the Recorded Voice to Backend
				isVoiceUploading.value = true;

				ctx.emit('onAudioRecorded', audioBlob);

				isVoiceUploading.value = false;

				// Move to Next Step of Wizard
				actions.moveNext();
			},

			initAudioContext(stream) {
				audioContext = new (window.AudioContext || window.webkitAudioContext)();
				const source = audioContext.createMediaStreamSource(stream);

				const scriptNode = audioContext.createScriptProcessor(1024, 1, 1);

				analyser.value = scriptNode;

				// Process audio data
				scriptNode.onaudioprocess = function (event) {
					const inputBuffer = event.inputBuffer;
					const inputData = inputBuffer.getChannelData(0);

					let sum = 0;
					for (let i = 0; i < inputData.length; i++) {
						sum += inputData[i] * inputData[i];
					}

					const rms = Math.sqrt(sum / inputData.length);
					const dbValue = 20 * Math.log10(rms);

					// Update the meter value
					volumeLevel.value = dbValue;
				};

				source.connect(scriptNode);
				scriptNode.connect(audioContext.destination);

				// analyser.value = audioContext.createAnalyser();
				// source.connect(analyser.value);
				// analyser.value.fftSize = 256;

				// const bufferLength = analyser.value.frequencyBinCount;
				// const dataArray = new Uint8Array(bufferLength);

				// const updateVolumeLevel = () => {
				//     analyser.value.getByteFrequencyData(dataArray);
				//     const total = dataArray.reduce((acc, val) => acc + val, 0);
				//     const volume = total / bufferLength / 255;
				//     volumeLevel.value = volume;
				//     requestAnimationFrame(updateVolumeLevel);
				// };

				// requestAnimationFrame(updateVolumeLevel);
			},

			moveNext: function () {
				ctx.emit('onMove', 2);
			},
		};

		return {
			dbValue,
			actions,
			textArray,
			textLine,
			showNext,
			// showSubmit,
			nextStep,
			volumeLevel,
			isRecording,
			isPaused,
			recordedAudio,
			mediaRecorder,
			audioChunks,
			isVoiceUploading,
			isFileError,
			fileErrorMessage,
			icons: {
				pause: mdiPause,
				mic: mdiMicrophone,
			},
		};
	},
};
</script>

<style scoped>
@keyframes colorfulPulseAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	35% {
		transform: scale(2);
		opacity: 0.5;
	}
	75% {
		transform: scale(2.5);
		opacity: 0.25;
	}
	100% {
		transform: scale(3);
		opacity: 0;
	}
}

.playAnimation {
	position: absolute;
	left: 48%;
	top: 8px;
	background: #468bd0 !important;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin: auto;
	animation: colorfulPulseAnimation 2s infinite !important;
}

.meter {
	background: #ddd;
	width: 150px;
	height: 10px;
	border-radius: 8px;
}

.volume {
	height: 100%;
	transition: width 0.1s ease-in-out;
	border-radius: 8px 0 0 8px;
}

.low-volume {
	background: red;
	width: 18%;
}

.medium-volume {
	width: 35%;
	background: yellow;
}

.optimum-volume {
	width: 70%;
	background: 'green';
}
</style>
