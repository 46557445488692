<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Share Your Voice Model (Optional)</h4>
				<p class="fs--14 shinyPurple--text text-center">
					Share your voice models with other broadcasters so they can use it on their stream!
				</p>

				<div v-if="voice" class="upload-area text-center mt-4">
					<v-row justify="center">
						<v-col cols="11" class="voice-shares">
							<v-form @submit.prevent="actions.shareVoice">
								<v-row no-gutters>
									<v-col cols="12" sm="8" md="9">
										<v-text-field
											v-model="userName"
											:loading="userLoading"
											clearable
											placeholder="Enter Broadcaster Name"
											:prepend-inner-icon="icons.search"
											background-color="purple"
											solo
											dense
											hide-details>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="4" md="3" class="pl-sm-2">
										<v-btn
											:disabled="!userName"
											:loading="userLoading"
											block
											type="submit"
											color="pink"
											class="mt-2 mt-sm-0"
											depressed>
											<span class="">Share Voice</span>
										</v-btn>
									</v-col>
								</v-row>
							</v-form>

							<!-- Added Users -->
							<v-list v-if="voice.shares.length > 0" dense class="mt-3 pa-0 user-list" flat>
								<v-list-item
									v-for="(user, index) in voice.shares"
									:key="user.id"
									class="mb-1 py-0"
									:class="index % 2 === 0 && 'even-row'">
									<v-list-item-content class="py-0">
										<div class="d-flex justify-space-between align-center">
											<div class="d-flex align-center">
												<v-avatar size="30" class="mr-2">
													<img
														:src="actions.profilePic(user.profilePic)"
														@error="$event.target.src = `${actions.defaultProfilePic()}`" />
												</v-avatar>
												<span class="fs--14 fw--400">{{ user.displayName }}</span>
											</div>
											<v-btn icon color="red" @click="actions.deleteUser(user)">
												<v-icon>{{ icons.delete }}</v-icon>
											</v-btn>
										</div>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="my-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back</v-btn
			>
			<v-btn class="my-3 my-sm-6 px-12" depressed @click="actions.saveChanges" color="pink">Submit</v-btn>
		</div>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import axios from '@axios';
import { maxVoiceShares } from '../../../../utils/constants';
import { showGlobalError } from '../../../../utils/error';
import { saveEvent } from '@/utils/gtag';

import { mdiMagnify, mdiDelete, mdiPlus, mdiAccountTieVoice } from '@mdi/js';
export default {
	props: {
		voice: {
			type: Object,
			default: null,
		},
	},
	emits: ['onMove', 'onSave'],
	setup(props, ctx) {
		const error = ref(false);
		const userLoading = ref(false);
		const userName = ref('');
		const search = ref('');

		const headers = [
			{ text: 'image', value: 'profilePic', class: 'user-avatar' },
			{ text: 'name', value: 'name', sortable: true, filterable: true },
			{ text: '', value: 'action', sortable: false, filterable: false },
		];

		const users = [
			{
				id: 1,
				name: 'GrullStation',
			},
			{
				id: 2,
				name: 'GrullStation',
			},
			{
				id: 3,
				name: 'GrullStation',
			},
		];

		const actions = {
			moveNext: function () {
				error.value = false;
			},
			moveBack: function () {
				ctx.emit('onMove', 3);
			},
			deleteUser(user) {
				const index = props.voice.shares.findIndex(v => v.id === user.id);
				props.voice.shares.splice(index, 1);
			},

			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},

			profilePic: function (profilePic) {
				if (profilePic) {
					return profilePic;
				} else {
					return actions.defaultProfilePic();
				}
			},
			shareVoice() {
				if (props.voice.shares.length == maxVoiceShares) {
					showGlobalError(`Sorry, you can only share to ${maxVoiceShares} people.`);
					return;
				}

				userLoading.value = true;
				axios
					.get(`/tts/vlab/broadcaster/${userName.value}`)
					.then(res => {
						if (res.data) {
							// Make sure it's not already in the list.
							if (!props.voice.shares.find(v => v.id === res.data.id)) {
								props.voice.shares.push(res.data);

								saveEvent({
									event: 'voice_model_shared',
									params: {
										event_category: 'engagement',
										event_label: 'User Shares a voice model',
									},
								});
							} else {
								showGlobalError('Broadcaster is already in the list.');
							}
						}
					})
					.finally(() => {
						userLoading.value = false;
					});
			},
			saveChanges() {
				ctx.emit('onSave', props.voice);
			},
		};

		return {
			actions,
			error,
			userLoading,
			search,
			userName,
			headers,
			users,
			icons: {
				search: mdiMagnify,
				delete: mdiDelete,
				plus: mdiPlus,
				voice: mdiAccountTieVoice,
			},
		};
	},
};
</script>

<style scoped>
.user-list {
	min-height: 30px;
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
