<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Upload Voice Sample:</h4>

				<div class="upload-area px-3 px-sm-0 text-center mt-4">
					<div class="text-center mt-1">
						<template v-if="!voice.audioBase64">
							<div>
								<v-icon color="boldPurple" size="120">{{ icons.uploadIcon }}</v-icon>
							</div>
							<v-btn @click="actions.pickFile" color="pink" min-height="46" class="px-15" depressed>
								Select File
							</v-btn>
						</template>
						<template v-if="voice.audioBase64">
							<div class="d-flex align-center justify-center mb-2">
								<v-icon size="18" class="mr-1" color="boldPurple">{{ icons.checkIcon }}</v-icon>
								<small class="shinyPurple--text"> File Uploaded </small>
							</div>
							<div class="text-center mt-4">
								<v-btn
									@click="actions.pickFile"
									color="pink"
									outlined
									:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
									min-height="46"
									depressed>
									Change File
								</v-btn>
							</div>
						</template>

						<input
							type="file"
							accept="audio/mp3,audio/mav"
							class="d-none"
							@change="actions.onFilePicked"
							ref="audioInput" />

						<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text">
							Upload an .mp3 or .wav file
							<a :href="VOICE_SCRIPT" target="_blank" class="text-underline">reading this script</a>
						</p>
						<p class="fs--13 fw--500 mb-0 mt-3 shinyPurple--text">Maximum size is 10MB</p>
					</div>

					<div class="text-right mt-2 px-1">
						<v-btn text color="pink" depressed class="text-capitalize" @click="actions.recordVoice()">
							Prefer Voice Record?
						</v-btn>
					</div>

					<v-checkbox v-model="voice.agreeTerms" color="pink" @change="actions.onAgree" class="mx-2">
						<template #label>
							<p class="fs--12 shinyPurple--text mb-0 text-center">
								I hereby confirm that i have all necessary rights or consents to upload and clone these
								voice samples and that i will not use the platform-generated content for any illegal,
								fraudulent, or harmful purpose. I reaffirm my obligation to abide by the TurboHype
								<strong class="text-underline fs--12 shinyPurple--text"> Terms of Service </strong>
							</p>
						</template>
					</v-checkbox>
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn
				class="my-3 my-sm-6 px-12"
				depressed
				@click="actions.moveNext"
				:disabled="!voice.audioBase64 || !voice.agreeTerms"
				color="pink"
				>Next</v-btn
			>
		</div>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { VOICE_SCRIPT } from '@/utils/utils';
import { showGlobalError } from '@/utils/error';
import { mdiCloudUploadOutline, mdiVolumeHigh, mdiCheckCircle } from '@mdi/js';
export default {
	props: {
		voice: {
			type: Object,
			required: true,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onMove', 'onRecordVoice', 'onAudioPicked', 'onAgree'],
	setup(props, ctx) {
		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const audioInput = ref(null);

		const actions = {
			moveNext: function () {
				ctx.emit('onMove', 2);
			},
			recordVoice() {
				ctx.emit('onRecordVoice');
			},
			pickFile: function () {
				audioInput.value.click();
			},
			onFilePicked: function (e) {
				const validTypes = ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav'];
				const maxFileSize = 10000000; // 10MB
				const file = e.target.files[0];

				if (file) {
					if (file.size > maxFileSize) {
						showGlobalError('Sorry, max recording size is 10MB.');
						return;
					} else if (!validTypes.includes(file.type)) {
						showGlobalError(
							'Sorry! This format is not supported. Audio must be uploaded in either WAV or MP3 file formats.',
						);
						return;
					}

					ctx.emit('onAudioPicked', file);
				}
			},
			onAgree(value) {
				ctx.emit('onAgree', value);
			},
			toggleAudio: function () {
				ctx.emit('onToggleAudio');
			},
		};

		return {
			actions,
			audioInput,
			isFileError,
			fileErrorMessage,
			VOICE_SCRIPT,
			icons: {
				uploadIcon: mdiCloudUploadOutline,
				volumeIcon: mdiVolumeHigh,
				checkIcon: mdiCheckCircle,
			},
		};
	},
};
</script>
