<template>
	<div class="tts-voice-lab pb-10">
		<page-title title="TTS Voice Lab"></page-title>

		<v-row class="mt-5">
			<v-col cols="12">
				<v-card class="voice-lab-header" min-height="250">
					<v-card-text class="py-3">
						<h3 class="fs--23 fw--500">How it works</h3>
						<v-row>
							<v-col cols="12" class="d-block d-sm-none text-right">
								<img
									class="xs-character"
									src="@/assets/images/mascot/voice-lab-sm.png"
									alt="TTS Voice Lab" />
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="5" xl="4">
								<p class="fieldFore--text fs--13 fw--400">
									Use this section to create ultra realistic recreation of your own voice. You can
									record on-site or download a script to record 5 minutes of your voice to create a
									Custom TTS. Once created, your A.I Voices will appear in your TTS Sticker voice
									dropdowns.
								</p>

								<v-row no-gutters>
									<v-col cols="12">
										<p class="white--text fs--13 fw--400 mt-2 text-center">
											Supports <span v-html="supportedVoicesString" />
										</p>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>

					<template v-if="$vuetify.breakpoint.mdAndUp">
						<img
							class="voice-lab-character"
							src="@/assets/images/mascot/voice-lab-xl.png"
							alt="TTS Voice Lab" />
					</template>

					<template v-if="$vuetify.breakpoint.smOnly">
						<img
							class="voice-lab-character"
							src="@/assets/images/mascot/voice-lab-sm.png"
							alt="TTS Voice Lab" />
					</template>
				</v-card>
			</v-col>
		</v-row>

		<!-- Demo Video -->
		<VoiceLabTutorial />

		<v-row class="mt-0">
			<v-col v-for="voice in voices.items" :key="voice.id" cols="12" sm="6" md="4" lg="3" class="mt-2">
				<template v-if="!isBitsEnabled">
					<LockedSlot @onRequireAffiliate="onRequireAffiliate" />
				</template>
				<template
					v-else-if="
						voice.ownership == VoiceLabOwnership.STREAMER && voice.isSlotConfigured && !voice.isSlotLocked
					">
					<VoiceSlot :item="voice" :loading="loading" @onDelete="onDelete" />
				</template>
				<template v-else-if="voice.ownership == VoiceLabOwnership.SHARED_TO">
					<SharedVoiceSlot :item="voice" @onDelete="onDelete" />
				</template>
				<template v-else-if="!voice.isSlotLocked && !voice.isSlotConfigured">
					<UnlockedSlot @onUploadVoice="voiceUpload = true" />
				</template>
				<template v-else-if="voice.isSlotLocked && !voice.isSlotConfigured">
					<EmptyShareSlot />
				</template>
			</v-col>
		</v-row>

		<NonAffiliatePopup :modal="affiliateModal" @close="affiliateModal = false">
			<template>Sorry! You must be Twitch Affiliate level or higher to upload a voice into this slot.</template>
		</NonAffiliatePopup>

		<UploadVoice
			v-if="voiceUpload"
			:uploader="voiceUpload"
			@onClose="voiceUpload = false"
			@onUploaded="onVoiceCreated" />

		<!-- Pass Created Voice as prop (voice) in the popup -->
		<VoiceCreatedPopup :voice="newVoice" :modal="voiceCreated" @close="onCloseVoiceCreated()" />
	</div>
</template>

<script>
import { onMounted, computed, reactive, ref } from '@vue/composition-api';
import axios from '@axios';
import PageTitle from '@/components/PageTitle.vue';
import VoiceLabTutorial from '@/views/streamer/tts-voice-lab/VoiceLabTutorial'
import EmptyShareSlot from '@/views/streamer/tts-voice-lab/EmptyShareSlot';
import LockedSlot from '@/views/streamer/tts-voice-lab/LockedSlot';
import SharedVoiceSlot from '@/views/streamer/tts-voice-lab/SharedVoiceSlot';
import UnlockedSlot from '@/views/streamer/tts-voice-lab/UnlockedSlot';
import VoiceSlot from '@/views/streamer/tts-voice-lab/VoiceSlot';
import UploadVoice from '@/views/streamer/tts-voice-lab/voice-model-wizard/VoiceUploader';
import VoiceCreatedPopup from '@/views/streamer/tts-voice-lab/VoiceCreatedPopup.vue';
import NonAffiliatePopup from '@/components/NonAffiliatePopup';
import { VoiceLabOwnership } from '@/utils/constants';
import { elSupportedLangs, formatSupportedLangs } from '@/utils/tts-utils';
import { isBitsEnabled } from '@/utils/auth';

export default {
	components: {
		PageTitle,
		VoiceLabTutorial,
		EmptyShareSlot,
		LockedSlot,
		SharedVoiceSlot,
		UnlockedSlot,
		VoiceSlot,
		UploadVoice,
		NonAffiliatePopup,
		VoiceCreatedPopup,
	},
	setup() {
		const voiceUpload = ref(false);
		const voiceCreated = ref(false);
		const newVoice = reactive({}); // The newly created voice.
		const affiliateModal = ref(false);
		const loading = ref(false);

		const voices = reactive({
			items: [],
		});

		const supportedVoicesString = computed(() => {
			return formatSupportedLangs(elSupportedLangs());
		});

		const onVoiceCreated = voice => {
			Object.assign(newVoice, voice);
			voiceCreated.value = true;
			fetchData();
		};

		const onCloseVoiceCreated = () => {
			voiceCreated.value = false;
			fetchData();
		};

		const fetchData = () => {
			loading.value = true;
			axios.get('/tts/vlab/voices').then(res => {
				voices.items.length = 0;
				voices.items.push(...res.data);
				loading.value = false;
			})
		};

		const onRequireAffiliate = () => {
			affiliateModal.value = true;
		};

		const onDelete = id => {
			fetchData();
		};

		onMounted(() => {
			fetchData();
		});

		return {
			affiliateModal,
			onRequireAffiliate,
			isBitsEnabled,
			voiceUpload,
			voiceCreated,
			voices,
			newVoice,
			loading,
			fetchData,
			onDelete,
			VoiceLabOwnership,
			supportedVoicesString,
			onVoiceCreated,
			onCloseVoiceCreated,
		};
	},
};
</script>

<style lang="scss" scoped>
.voice-lab-header {
	position: relative;

	.voice-lab-character {
		position: absolute;
		height: 110%;
		bottom: 0px;
		right: 0px;
	}

	.xs-character {
		margin-right: -20px;
	}
}

@media screen and (max-width: 700px) {
	.voice-lab-header {
		overflow: hidden;
		.voice-lab-character {
			height: 90%;
			right: -10px;
			overflow: hidden;
		}
	}
}
</style>
