<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Review Audio Recording</h4>

				<div v-if="voice && !isVoiceLoading" class="upload-area text-center mt-4">
					<v-row justify="center">
						<v-col cols="12" md="7" lg="6" xl="5">
							<MusicPlayer
								v-if="voice.recordedBlob && voice.type === VoiceLabUploadType.RECORD"
								:uploadPreview="true"
								:id="'shared-voice-preview-' + new Date().getTime()"
								:audio="voice.recordedBlob" />

							<MusicPlayer
								v-if="voice.recordedBlob && voice.type === VoiceLabUploadType.MANUAL"
								:uploadPreview="true"
								:id="'shared-voice-preview-' + new Date().getTime()"
								:audio="voice.recordedBlob" />
						</v-col>
					</v-row>

					<div class="d-flex flex-column align-center justify-center">
						<p class="fs--13 mt-1 shinyPurple--text">
							Happy with your recording? If so, select NEXT. If you want to start over, select BACK
						</p>
					</div>
				</div>

				<div v-if="isVoiceLoading" class="text-center">
					<RecordingLoading />
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn
				class="my-3 my-sm-6 px-12 mr-2"
				:disabled="isVoiceLoading"
				depressed
				@click="actions.moveBack"
				outlined
				color="pink"
				>Back</v-btn
			>
			<v-btn
				class="my-3 my-sm-6 px-12"
				:disabled="isVoiceLoading"
				depressed
				@click="actions.moveNext"
				color="pink"
				>Next</v-btn
			>
		</div>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import axios from '@axios';
import MusicPlayer from '../MusicPlayer';
import RecordingLoading from '@/components/RecordingLoading.vue';
import { VoiceLabUploadType } from '@/utils/constants';
import { mdiPlay, mdiPause } from '@mdi/js';

export default {
	components: {
		MusicPlayer,
		RecordingLoading,
	},
	props: {
		voice: {
			type: Object,
			default: null,
		},
	},
	emits: ['onMove', 'onAccentChange'],
	setup(props, ctx) {
		const error = ref(false);
		const isPlaying = ref(false);
		const isVoiceLoading = ref(false);

		const actions = {
			moveNext: function () {
				// Create the voice model and then move next.
				isVoiceLoading.value = true;
				axios
					.post('/tts/vlab/voice-model', props.voice)
					.then(res => {
						props.voice.id = res.data.id;
						ctx.emit('onMove', 3);
					})
					.catch(error => console.log(error))
					.finally(() => {
						isVoiceLoading.value = false;
					});
			},
			moveBack: function () {
				ctx.emit('onMove', 1);
			},
			onAccentChange(value) {
				ctx.emit('onAccentChange', value);
			},
		};

		return {
			actions,
			isPlaying,
			isVoiceLoading,
			error,
			VoiceLabUploadType,
			icons: {
				play: mdiPlay,
				pause: mdiPause,
			},
		};
	},
};
</script>
