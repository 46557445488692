<template>
    <div class="mb-4 voice-linked-stickers">
        <v-slide-group v-model="slide" class="pa-0" show-arrows>
            <v-slide-item v-for="item in stickers" :key="item.id" style="width: 55px;">
                <v-avatar tile size="75" class="mx-1 cursor-pointer" @click="actions.gotoStickers">
                    <AssetsTTS :item="item" size="25" />
                </v-avatar>
            </v-slide-item>
            <v-slide-item v-if="stickers.length < 1">
                <v-btn color="pink" class="mx-1" @click="actions.addSticker">
                    <v-icon class="mr-1" size="18" color="white">{{ icons.open }}</v-icon>
                    <span class="fs--13 fw--400 white--text">Assign Voice</span>
                </v-btn>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router'
import { getHostedAssetPath } from '@/utils/config';
import { mdiOpenInNew } from '@mdi/js';
import AssetsTTS from '../stickers/AssetsTTS.vue'

export default {
    components: {
        AssetsTTS
    },
    props: {
        stickers: {
            type: Array
        }
    },
    setup(props, ctx) {
        const slide = ref(0);
        const actions = {
            addSticker() {
                ctx.emit('addSticker')
            },
            gotoStickers() {
                router.push('/stickers?tab=TTS')
            }
        }

        return {
            slide,
            actions,

            icons: {
                open: mdiOpenInNew
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.base-image {
	position: relative;
	margin: auto;
    width: 75px;
    max-width: 75px !important;
}
</style>