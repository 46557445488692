<template>
	<div>
		<v-card class="mt-5 tts-tutorial">
			<v-card-text class="py-3">
				<div class="d-flex align-center">
					<v-btn color="pink" depressed @click="setTutorial">
						<v-icon class="mr-1">{{ icons.youtube }}</v-icon> Watch Now</v-btn
					>

					<h4 class="fs--16 fw--500 ml-3">
                        Check out our Voice Lab Setup video for tips and tricks on how to create the best quality A.I. TTS!
					</h4>
				</div>
			</v-card-text>
		</v-card>

		<GenericPopup :modal="tutorial" :persistent="true" :maxWidth="750" @close="hideTutorial">
			<template #primaryHeader> TTS Voice Lab Tutorial </template>

			<template #secondaryHeader>
				Check out this video for a quick start guide on TTS Voice Lab Setup.
			</template>

			<template #embed>
				<div id="ytPlayer"></div>
				<div class="video-container mb-3">
					<iframe
						ref="youtubeIframe"
						id="youtube-iframe"
						width="560"
						height="315"
						:src="youtubeLink"
						frameborder="0"
						allowfullscreen></iframe>
				</div>
			</template>
		</GenericPopup>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiYoutube } from '@mdi/js';
import GenericPopup from '@/components/GenericPopup.vue';

export default {
	components: {
		GenericPopup,
	},
	setup() {
		const tutorial = ref(false);
		const youtubeLink = 'https://www.youtube.com/embed/lDuXzuTux00';

		const youtubeIframe = ref('');

		const setTutorial = () => {
			tutorial.value = true;
			const iframe = youtubeIframe.value;
			if (iframe) {
				iframe.src = youtubeLink;
			}
		};

		const hideTutorial = () => {
			tutorial.value = false;
			const iframe = youtubeIframe.value;
			if (iframe) {
				iframe.src = '';
			}
		};

		return {
			tutorial,
			youtubeLink,
			youtubeIframe,
			setTutorial,
			hideTutorial,

			icons: {
				youtube: mdiYoutube,
			},
		};
	},
};
</script>

<style scoped>
.tts-tutorial {
	background: -webkit-linear-gradient(to right, #0dcee1, #c16dd7, #cd3bd7) !important;
	background: -o-gradient(to right, #0dcee1, #c16dd7, #cd3bd7) !important;
	background: -ms-gradient(to right, #0dcee1, #c16dd7, #cd3bd7) !important;
	background: -moz-gradient(to right, #0dcee1, #c16dd7, #cd3bd7) !important;
	background: linear-gradient(to right, #0dcee1, #c16dd7, #cd3bd7) !important;
}

.video-container {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width * 100) */
}

#youtube-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
