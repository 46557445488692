<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text class="pb-4">
			<h4 class="fs--15 fw--500 text-center">Create New TTS Voice</h4>

			<div class="text-center upload-voice d-flex flex-column justify-center align-center">
				<v-btn fab color="lightenPurple" width="100" height="100" @click="actions.requiresAffiliate">
					<v-icon size="32" color="secondary">{{ icons.lock }}</v-icon>
				</v-btn>

				<v-btn color="lightenPurple" class="mt-4 px-3 px-lg-2 px-xl-3" depressed>
					<span class="shinyPurple--text" :class="$vuetify.breakpoint.lgOnly ? 'fs--11' : 'fs--12'"
						>Requires Twitch Affiliate Level</span
					>
				</v-btn>
			</div>

			<v-divider class="purpleDivider"></v-divider>

			<div class="text-center pa-4">
				<span class="fs--15 fw--500">Voice Sharing</span>
				<p class="fs--12 fw--100 mt-2">Share your TTS Voice with your friends so they can use it.</p>

				<v-btn depressed color="lightenPurple">
					<v-icon class="mr-2">{{ icons.addUser }}</v-icon>
					<span class="fs--13 fw--400">Share Voice</span>
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mdiLock, mdiAccountMultiplePlus, mdiDelete } from '@mdi/js';
export default {
	props: {},
	components: {},
	emits: ['onRequireAffiliate'],
	setup(props, ctx) {
		const actions = {
			requiresAffiliate() {
				ctx.emit('onRequireAffiliate');
			},
		};
		return {
			actions,
			icons: {
				lock: mdiLock,
				addUser: mdiAccountMultiplePlus,
				delete: mdiDelete,
			},
		};
	},
};
</script>

<style scoped>
.upload-voice {
	height: 175px;
	width: 100%;
}
</style>
