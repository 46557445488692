<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Preview and Fine Tune your Voice Model</h4>

				<div class="upload-area text-center mt-4">
					<template v-if="voice">
						<v-row justify="center">
							<v-col v-if="!isInDataRangeChange" cols="12" md="7" lg="6" xl="5">
								<MusicPlayer
									v-if="voice.recordedBlob && voice.type === VoiceLabUploadType.RECORD"
									:uploadPreview="true"
									:id="'shared-voice-preview-' + new Date().getTime()"
									:audio="audioUrl" />

								<MusicPlayer
									v-if="voice.recordedBlob && voice.type === VoiceLabUploadType.MANUAL"
									:uploadPreview="true"
									:id="'shared-voice-preview-' + new Date().getTime()"
									:audio="audioUrl" />
							</v-col>
							<v-col v-else cols="12" md="7" lg="6" xl="5" style="height: 75px"></v-col>
						</v-row>

						<v-row justify="center" no-gutters>
							<v-col
								cols="12"
								class="mt-1 d-flex align-center justify-space-between"
								style="max-width: 330px">
								<span class="fs--14 text-md fw--500">Tonal Variance:</span>
								<v-tooltip top color="tooltipBg">
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-img
												width="40"
												class="pointer-events-none"
												src="@/assets/icons/info.png"
												@click.stop></v-img>
										</div>
									</template>
									<strong class="offWhite--text">
										Decreasing the Tonal Variance can help increase variability <br />
										and make your voice sound more lifelike between uses.<br />
										Increasing the Tonal Variance will increase the similarity <br />
										between each use, however, it could also make your voice <br />
										sound more monotone.
									</strong>
								</v-tooltip>
							</v-col>
						</v-row>

						<v-row justify="center" no-gutters>
							<v-col
								cols="12"
								class="d-flex align-center justify-space-between"
								:style="`max-width: ${columnWidth}px`">
								<v-slider
									v-model="voice.stability"
									@start="isInDataRangeChange = true"
									@end="isInDataRangeChange = false"
									color="pink"
									:min="30"
									:max="100"
									thumb-color="pinkItem"
									thumb-label
									track-fill-color="pinkItem"
									hide-details>
									<template #thumb-label>
										<span class="px-2">{{ voice.stability }}%</span>
									</template>
								</v-slider>
							</v-col>
						</v-row>

						<v-row justify="center" no-gutters>
							<v-col
								cols="12"
								class="d-flex align-center justify-space-between"
								:style="`max-width: ${columnWidth}px`">
								<span class="fs--14 text-md fw--500 ma-0 text-left"
									>Multi-Language &amp; Accent Enhancement</span
								>
								<v-switch
									v-model="voice.enableAccentEnhancement"
									@change="actions.onAccentChange"
									color="pink"
									class="ma-0 mr-1"
									hide-details>
								</v-switch>
								<v-tooltip top color="tooltipBg">
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-img width="40" src="@/assets/icons/info.png"></v-img>
										</div>
									</template>
									<strong
										class="offWhite--text"
										v-html="`Enhance:<br /> ${supportedVoicesString}`"></strong>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row justify="center" no-gutters>
							<v-col
								cols="12"
								class="d-flex align-center justify-space-between"
								:style="`max-width: ${columnWidth}px`">
								<p class="fs--13 mt-1 shinyPurple--text">Best for non-english channels</p>
							</v-col>
						</v-row>

						<v-row justify="center" no-gutters>
							<v-col
								cols="12"
								class="d-flex align-center justify-space-between"
								:style="`max-width: ${columnWidth}px`">
								<span class="fs--14 text-md fw--500">Style Exaggeration:</span>
								<v-tooltip top color="tooltipBg">
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-img
												width="40"
												class="pointer-events-none"
												src="@/assets/icons/info.png"
												@click.stop></v-img>
										</div>
									</template>
									<strong class="offWhite--text">
										High values are recommended if the style of the speech <br />
										should be exaggerated compared to the uploaded audio.
									</strong>
								</v-tooltip>
							</v-col>
							<v-col
								cols="12"
								class="d-flex align-center justify-space-between"
								:style="`max-width: ${columnWidth}px`">
								<v-slider
									v-model="voice.style"
									@start="isInDataRangeChange = true"
									@end="isInDataRangeChange = false"
									color="pink"
									:min="0"
									:max="50"
									:step="10"
									thumb-color="pinkItem"
									thumb-label
									track-fill-color="pinkItem"
									hide-details>
									<template #thumb-label>
										<span class="px-2">{{ voice.style }}%</span>
									</template>
								</v-slider>
							</v-col>
						</v-row>
					</template>
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<template>
				<v-btn class="my-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
					>Back</v-btn
				>
				<v-btn class="my-3 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
			</template>
		</div>
	</v-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { VoiceLabUploadType } from '@/utils/constants';
import { mdiPlay, mdiPause } from '@mdi/js';
import MusicPlayer from '../MusicPlayer';
import {
	getVoicePreviewUrlInProcess,
	elSupportedLangsNonEnglish,
	formatSupportedLangs,
} from '../../../../utils/tts-utils';

export default {
	components: {
		MusicPlayer,
	},
	props: {
		voice: {
			type: Object,
			default: null,
		},
	},
	emits: ['onMove', 'onAccentChange'],
	setup(props, ctx) {
		const error = ref(false);
		const isPlaying = ref(false);
		const isInDataRangeChange = ref(false);

		const audioUrl = computed(() => {
			return getVoicePreviewUrlInProcess(
				props.voice.id,
				props.voice.enableAccentEnhancement,
				props.voice.stability,
				props.voice.style,
			);
		});

		const supportedVoicesString = computed(() => {
			return formatSupportedLangs(elSupportedLangsNonEnglish());
		});

		const actions = {
			moveNext: function () {
				ctx.emit('onMove', 4);
			},
			moveBack: function () {
				ctx.emit('onMove', 2);
			},
			onAccentChange(value) {
				ctx.emit('onAccentChange', value);
			},
			// After a user completes a slider change.
			onSliderChangeEnd() {},
		};

		return {
			audioUrl,
			supportedVoicesString,
			actions,
			isPlaying,
			isInDataRangeChange,
			error,
			VoiceLabUploadType,
			columnWidth: 330,
			icons: {
				play: mdiPlay,
				pause: mdiPause,
			},
		};
	},
};
</script>
